<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postArticleData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Title *"
            label-for="title"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="title"
            >
              <b-form-input
                id="title"
                v-model="formData.title"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Slug *"
            label-for="slug"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="slug"
            >
              <b-form-input
                id="slug"
                v-model="formData.slug"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Slug"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Featured Image *"
            label-for="featured_image"
            label-cols-md="4"
          >
            <b-avatar
              ref="previewEl"
              :src="formData.featured_image"
              size="90px"
              rounded
            />
            <div class="up">
              <b-button
                variant="primary"
                @click="$refs.refInputEl.click()"
              >
                <input
                  id="file"
                  ref="refInputEl"
                  type="file"
                  accept="image/*"
                  class="d-none"
                  @change="inputImageRenderer"
                >
                <!-- ada d-none dibawah type="file" -->
                <span class="d-none d-sm-inline">Update</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
              >
                <span class="d-none d-sm-inline">Remove</span>
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="category"
          >
            <b-form-group
              label="Category*"
              label-cols-md="4"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="formData.category"
                placeholder="Please Select Category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="categoryData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Content *"
            label-for="content"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="content"
            >
              <quill-editor
                id="content"
                v-model="formData.content"
                :state="errors.length > 0 ? false:null"
                type="text"
                class="ql-container"
                style="min-height:300px;margin-bottom:50px;"
                placeholder="Content"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BAvatar,
  // BFormFile,
  // BMedia,
} from 'bootstrap-vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    quillEditor,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    formData: {
      featured_image: String,
      type: String,
      required: true,
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.formData.featured_image = base64
    })
    return {
      content: [],
      categoryData: [1, 2, 3],
      // formData: {},
      required,
      userData: {},
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
    this.getUser()
    this.getCategoryData()
    this.getArticleData()
  },
  methods: {
    getArticleData() {
      axios
        .get(`/articles/${this.$route.params.id}`)
        .then(res => {
          this.formData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getCategoryData() {
      axios
        .get('/categories')
        .then(res => {
          this.categoryData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getUser() {
      const userLocalStorage = localStorage.getItem('user')
      const userDecoded = Buffer.from(userLocalStorage, 'base64').toString('utf-8')
      const userParsed = JSON.parse(userDecoded)
      this.userData = userParsed
    },
    postArticleData() {
      this.$refs.simpleRules.validate().then(success => {
        const postData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.formData)) {
          postData.append(key, value)
        }

        // eslint-disable-next-line no-restricted-syntax
        // for (const pair of postData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }
        postData.append('created_by', this.userData.id === undefined ? '' : this.userData.id)
        postData.append('category_id', this.formData.category === undefined ? '' : this.formData.category.id)
        postData.delete('category')

        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            axios.post(`/articles/${this.$route.params.id}`, postData).then(res => {
              this.form = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'article-all' })
              })
            }),
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.2rem;
    margin-bottom: 2px;
    padding-bottom: 10px;
    height: 150px;
}

.ql-container{
  min-height: inherit;
}

.up {
  padding-top: 10px;
}
</style>
