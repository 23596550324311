var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.postArticleData.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.OnReset.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Title *","label-for":"title","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null,"type":"text","placeholder":"Title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Slug *","label-for":"slug","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","state":errors.length > 0 ? false:null,"type":"text","placeholder":"Slug"},model:{value:(_vm.formData.slug),callback:function ($$v) {_vm.$set(_vm.formData, "slug", $$v)},expression:"formData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Featured Image *","label-for":"featured_image","label-cols-md":"4"}},[_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.formData.featured_image,"size":"90px","rounded":""}}),_c('div',{staticClass:"up"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"id":"file","type":"file","accept":"image/*"},on:{"change":_vm.inputImageRenderer}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Update")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary"}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Remove")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category*","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"placeholder":"Please Select Category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.categoryData},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Content *","label-for":"content","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"ql-container",staticStyle:{"min-height":"300px","margin-bottom":"50px"},attrs:{"id":"content","state":errors.length > 0 ? false:null,"type":"text","placeholder":"Content"},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }